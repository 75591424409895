@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/montserrat/Montserrat-Regular.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/montserrat/Montserrat-SemiBold.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/montserrat/Montserrat-Bold.otf') format('truetype');
    font-weight: bolder;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/montserrat/Montserrat-Light.otf') format('truetype');
    font-weight: lighter;
    font-style: normal;
}

* {
    font-family: 'Montserrat' !important;
}

.fs-big {
    font-size: 5rem;
}

/** BACKGROUNDS **/
.bg-primary {
    background-color: #02CEFF !important;
}
.bg-secondary {
    background-color: #B6DD10 !important;
}
.bg-dark {
    background-color: #013340 !important;
}
.bg-green {
    background-color: #33C981 !important;
}
.bg-light {
    background-color: #FEFEFE !important;
}
.bg-gray {
    background-color: #ECECEC !important;
}
.bg-white-dark {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #013340 50%, #013340 100%);
}
.bg-gray-2 {
    background-color: #F6F8FA !important;
}

/** BUTTONS **/
.btn {
    border-radius: 0;
    border-width: 2px;
}
.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:focus-visible {
    background-color: #02CEFF !important;
    border-color: #02CEFF !important;
    color: #ffffff !important;
}
.btn-primary:hover {
    background-color: #02CEFF !important;
    border-color: #02CEFF !important;
    color: #ffffff !important;
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:focus-visible {
    background-color: #B6DD10 !important;
    border-color: #B6DD10 !important;
    color: #ffffff !important;
}
.btn-secondary:hover {
    background-color: #B6DD10 !important;
    border-color: #B6DD10 !important;
    color: #ffffff !important;
}

.btn-dark,
.btn-dark:focus,
.btn-dark:active,
.btn-dark:focus-visible {
    background-color: #013340 !important;
    border-color: #013340 !important;
    color: #ffffff !important;
}
.btn-dark:hover {
    background-color: #013340 !important;
    border-color: #013340 !important;
    color: #ffffff !important;
}

.btn-green,
.btn-green:focus,
.btn-green:active,
.btn-green:focus-visible {
    background-color: #33C981 !important;
    border-color: #33C981 !important;
    color: #ffffff !important;
}
.btn-green:hover {
    background-color: #33C981 !important;
    border-color: #33C981 !important;
    color: #ffffff !important;
}

.btn.btn-link {
    color: #B6DD10;
    text-decoration: none;
    border-bottom-width: 2px;
    border-bottom-color: transparent;
    padding: 1px;
}
.btn.btn-link:hover {
    border-bottom-width: 2px;
    border-bottom-color: #B6DD10;
}

/** FORMS **/
.form-control {
    border-radius: 0;
    border-color: #cbd6e2;
    background-color: #f5f8fa;
    text-align: center;
    color: #666666 !important;
}

/** TEXTS **/
.text-primary {
    color: #02CEFF !important;
}
.text-secondary {
    color: #B6DD10 !important;
}
.text-gray {
    color: #6F8E87 !important;
}
.text-dark {
    color: #013340 !important;
}

/** SWEET ALERT **/
div:where(.swal2-icon) {
    border: none !important;
}

/** DROPDOWNS **/
.dropdown.dropdown-no-arrow .dropdown-toggle::after {
    display: none;
}

/** NAVBAR **/
.navbar-nav .nav-link.active {
    border-bottom: solid 8px #02CEFF;
    padding-bottom: calc(1rem - 8px) !important;
}

.right-0 {
    right: 0;
}

.rounded-start-50 {
    border-top-left-radius: 10rem;
    border-bottom-left-radius: 10rem;
    padding-left: 7.5rem;
}

img.image {
    padding: 0 10px;
    border: none;
}
.img-div.image-popup {
    box-shadow: none !important;
}

/** BORDER **/
.border-primary {
    border-color: #02CEFF !important;
}
.border-dark {
    border-color: #013340 !important;
}

/** TABS **/
.nav-tabs .nav-link {
    border: solid 2px #013340;
    border-radius: 25px;
    background-color: transparent;
    color: #013340;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:active,
.nav-tabs .nav-link:focus-visible,
.nav-tabs .nav-link.active {
    border: solid 2px #013340;
    background-color: #013340;
    color: #ffffff;
}

/** FONTS **/
.fs-1-2 {
    font-size: 2rem;
}
@media (min-width: 992px) {
    .fs-1-2 {
        font-size: 4rem;
    }
}

.lh-120 {
    line-height: 120%;
}
